<template>
    <div style="display: flex; align-items: center; justify-content: space-between;">
        <div class="headerHeading">{{$t("Customer.OpdAppointments.OPD_Appointments")}}</div>
        <div @click="takeMeHome" style="cursor: pointer;">
            <img src="https://s3iconimages.mymedicine.com.mm/crossMark.svg" alt="" style="width: 25px; height: 25px;">   
        </div>
    </div>
</template>

<script>
import BodyContainer from '../../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../../templates/headerContainerOPDBooking.vue';
import mainContainer from '../../../../templates/mainContainer.vue';



export default {
  components: { mainContainer, BodyContainer, HeaderContainer },
    name: 'HospitalHeaderComp',
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
        };
    },
    async mounted() {
        // this.currentUser = this.$cookies.get('nurse_token');

    },
    methods: {
        takeMeHome() {
            this.$router.push({
                name: 'CustomerHomeScreen',
            });
        },
        async loadHomePageData() {
        },
    },
};

</script>
<style  scoped>
.headerHeading {
    color: white;
    font-weight: 600;
}

</style>