<template>
    <div style="width: 100%;">
        <div class="mainContainer">
            <div class="bottomBorder" style="display: flex; justify-content: space-between; align-items: center; padding: 12px 16px 12px 16px;">
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                    <div style="display: flex; gap:10px; font-size: 14px; align-items: center">
                        <div style="font-weight: 500; font-size: 14px;">{{$t("Customer.OpdAppointments.Select_Speciality")}}</div>
                        <div style="color: #1467BF; font-weight: 500; cursor: pointer;" @click="clearAll" v-if="this.finalSpecialityList.length > 0">({{$t("Customer.OpdAppointments.Clear_All")}})</div>
                    </div>
                    <div> {{ this.specialityName }}
                        <span v-if="this.finalSpecialityList.length > 1">  + {{ this.finalSpecialityList.length -1 }} </span>
                    </div>
                    
                </div>
                <v-btn  outlined style=" color: #1467BF;" @click="openRegionSelector">{{$t("Customer.OpdAppointments.Select")}}</v-btn>
            </div>
            <!-- <div class="bottomBorder" style="display: flex; justify-content: space-between; align-items: center; padding: 12px 16px 12px 16px;">
                <div style="width: 100%; display: flex; gap: 8px;">
                <div class="input-wrapper" style="flex-grow: 1; background-color: transparent;" id="searchBox">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/searchIcon.svg" alt="Icon" class="input-icon">
                    <input type="text" class="input-box" placeholder="Search" v-model="searchQuery" @input="searchFilter" style="color: #4f4f4f;">
                </div>
                <div>
                    <v-btn outlined style="color: #e8e8eb;" @click="() => {filterSheet = true;}">
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/filterGrey.svg" alt="Icon">
                    </v-btn>
                </div>
            </div>
            </div> -->
        </div>
        <v-bottom-sheet  :persistent="true" v-model="specialitySheet" max-width="425px">
            <v-sheet
            class="text-center"
            height="370px"
            >
            <div class="searchStateBottomContainer">
                <div>
                    <div class="selectRegionBottomSheetHeading">
                        <div>{{$t("Customer.OpdAppointments.Select_Speciality")}}</div>
                        <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="closeBottomSheet" style="cursor: pointer;"/>
                    </div>
                    <div class="" >
                        <div class="input-wrapper" style="background-color: #F4F6FA; border: none; padding: 12px 16px 12px 16px;">
                            <img src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="Icon" class="input-icon">
                            <input type="text" class="input-box input-box-spec" placeholder="Search" style="color: #4f4f4f;" v-model="searchQuery" @input="searchFilter" >
                        </div>
                    </div>
                </div>    
                <div class="stateChipsContainer">
                    <div class="specialtyBox" v-for="(item) in showSpecialtyList" :key="item.id" @click="selectSpeciality(item)">
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <img src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="" v-if="selectedSpeciality.find((x) => (x.id == item.id))">
                            {{ item.name }}</div>
                    </div>
                </div>
                <div class="stateBottomSheetBottomBar" style="z-index: 10000; background-color: white;">
                    <div style="color: #1467BF; font-weight: 500; cursor: pointer;" @click="clearAll">{{$t("Customer.OpdAppointments.Clear_All")}}</div>
                    <v-btn color="#48acef" depressed style="color: white;" @click="applySpecialityFilters">{{$t("Customer.OpdAppointments.Apply")}}</v-btn>
                </div>
            </div>
            </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet  :persistent="true" v-model="filterSheet" max-width="425px">
            <v-sheet
            class="text-center"
            height="470px"
            >
            <div class="searchStateBottomContainer">
            <div>
                <div class="selectRegionBottomSheetHeading">
                    <div>{{$t("Customer.priceRangeComponent.Filters")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="closeBottomSheet" style="cursor: pointer;"/>
                </div>
            </div>    
            <div>
                <v-tabs vertical background-color="#f2f2f2">    
                    <v-tab>
                        <div>{{$t("Customer.BookAppointment.Gender")}}</div>
                    </v-tab>
                    <v-tab>
                        <div>{{$t("Customer.PackageAppointments.Academic_title")}}</div>
                    </v-tab>
                    <v-tab-item>
                        <div class="filterBody"></div>
                    </v-tab-item>
                    <v-tab-item>                        
                        <div class="filterBody"></div>
                    </v-tab-item>
                    <v-tab-item>                        
                        <div class="filterBody"></div>
                    </v-tab-item>

                </v-tabs>
            </div>
            <div class="filterBottomBar">
                <div style="flex-grow: 1;"></div>
                <div style="display: flex; gap: 10px;">
                    <v-btn plain flat style="color: #1467BF; font-weight: bolder;">{{$t("Customer.OpdAppointments.Clear_All")}}</v-btn>
                    <v-btn color="#48acef" style="color: white;">{{$t("Customer.OpdAppointments.Apply")}}</v-btn>
                </div>
            </div>
        </div>
        </v-sheet>
        </v-bottom-sheet>
    </div>
    
</template>

<script>
export default {
    name: 'OPDPackageSelectSpeciality',
    props: ['tabName'],
    data: function() {
        return {
            filterSheet: false,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            specialitySheet: false,
            selectedSpeciality: [],
            stateList: [],
            finalSpecialityList: [],
            specialityName: '',
            searchQuery: '',
            showSpecialtyList: []
        };
    },
    async mounted() {
    },
    computed: {
        specialtyList () {
            let tabValue = this.tabName;
            switch (tabValue) {
                case 'OPDDOCTORFLOW':
                case 'HOSPITALOPDFLOW':
                this.showSpecialtyList = this.$store.state.OPDConfigCommonState.opdSpec; 
                return this.$store.state.OPDConfigCommonState.opdSpec;        
                case 'HOSPITALPACKAGEFLOW':
                case 'PACKAGEFLOW':
                this.showSpecialtyList = this.$store.state.OPDConfigCommonState.packageSpec;
                return this.$store.state.OPDConfigCommonState.packageSpec;        
            }
        },
        
    },
    methods: {
        searchFilter() {
            if (this.searchQuery.length >= 3) {
                this.showSpecialtyList = this.specialtyList.filter((item) => (item.name.toLowerCase().includes(this.searchQuery.toLowerCase())));
            } else if(this.searchQuery.length == 0) {
                this.showSpecialtyList = this.specialtyList;
            }
        },  
        selectSpeciality(item) {
            let itemExist = this.selectedSpeciality.findIndex((x) => (x.id == item.id));
            if (itemExist>=0) {
                this.selectedSpeciality = this.selectedSpeciality.filter((x) => (x.id != item.id));
            } else {
                this.selectedSpeciality.push(item);
            }
        },
        applySpecialityFilters() { 
            this.finalSpecialityList = this.selectedSpeciality;
            if (this.finalSpecialityList.length == 0) {
                this.specialityName = '';
            } else {
                this.specialityName = this.specialtyList.find((item) => (item.id) == this.selectedSpeciality[0].id).name;
            }
            
            switch(this.tabName) {
                case 'PACKAGEFLOW':
                    this.$store.dispatch('opdPackageState/addPackageSpecialityFilter', {specialities: this.selectedSpeciality, tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
                case 'OPDDOCTORFLOW':
                    this.$store.dispatch('addDoctorSpecialityFilterDoctorFlow', {specialities: this.selectedSpeciality, tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
                case 'HOSPITALOPDFLOW':
                    this.$store.dispatch('addDoctorSpecialityFilterHospitalFlow', {specialities: this.selectedSpeciality, tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
                case 'HOSPITALPACKAGEFLOW':
                    this.$store.dispatch('OPDPackageParticularHospitalState/addPackageSpecialityFilter', {specialities: this.selectedSpeciality, tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
            }
        },
        clearAll() { 
            this.selectedSpeciality = [];
            this.finalSpecialityList = [];
            this.specialityName = '';
            switch(this.tabName) {
                case 'PACKAGEFLOW':
                    this.$store.dispatch('opdPackageState/addPackageSpecialityFilter', {specialities: [], tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
                case 'OPDDOCTORFLOW':
                    this.$store.dispatch('addDoctorSpecialityFilterDoctorFlow', {specialities: [], tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
                case 'HOSPITALOPDFLOW':
                    this.$store.dispatch('addDoctorSpecialityFilterHospitalFlow', {specialities: [], tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
                case 'HOSPITALPACKAGEFLOW':
                    this.$store.dispatch('OPDPackageParticularHospitalState/addPackageSpecialityFilter', {specialities: [], tabName: this.tabName});
                    this.specialitySheet = false;
                    return;
            }
        },
        
        closeBottomSheet() {
            this.specialitySheet = false;
        },
        openRegionSelector() {
            this.specialitySheet = true;
            this.showSpecialtyList = this.specialtyList;
        },
        async loadHomePageData() {

        },
    },
};

</script>
<style  scoped>
.filterBottomBar {
    display: flex;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    align-items: center;
    border-top: 1px solid #e8e8eb;

}
.specialtyBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-bottom: 0.1px solid #e8e8eb;
    cursor: pointer;
}
.input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #e8e8eb;
    border-radius: 6px;
    padding: 5px 10px;
    background-color: #f9f9f9;
}
.input-icon {
    height: 20px; /* Adjust based on your icon's size */
    margin-right: 10px;
}
.filterBody {
    min-height: 360px;
}
.input-box {
    border: none;
    outline: none;
    background-color: transparent;
    flex-grow: 1;
}
.mainContainer {
    width: 100%;
    background-color: white;
    
}
.bottomBorder {
    border-bottom: 1px solid #e8e8eb;
}
.searchHospital {
    width: 100%;
    border-bottom: 1px solid #e8e8eb;
    padding: 12px 16px 12px 16px;


}
.v-input >>> .v-text-field__details {
    display: none !important;
}
.stateName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.stateList {
    font-weight: 400;
    font-size: 14px;
    color: #4f4f4f;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.selectRegionBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.stateChipsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 250px;
    padding-bottom: 50px;
}
.stateBottomSheetBottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 16px;
    border-top: 1px solid #e8e8eb;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 425px;
}
.searchStateBottomContainer {
    display: flex;
    flex-direction: column;
}
.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}
.bottomBorder >>> .v-input {
    margin: 0;
}
.bottomBorder >>> .v-messages {
    display: none;
}
.bottomBorder >>> .v-input__slot {
    margin: 0;
}
</style>